import React from 'react'
import './action-buttons.scss'

import { signInWithGoogle } from '../../utils/firebaseUtils'
import { Tooltip } from '@material-ui/core';
import WatchLaterOutlinedIcon from '@material-ui/icons/WatchLaterOutlined';
import WatchLaterIcon from '@material-ui/icons/WatchLater';

const AddToWatchlist = ({
    addToWatchlist,
    currentUser,
    mediaProps,
    removeFromWatchlist,
    setReactGAClickEvent
}) => {
    console.log('add to watchlist')
    if (currentUser) {
        if (currentUser.watchlist && currentUser.watchlist[mediaProps.id]) {
            return (
                <a>
                    <button
                        className="actions-button"
                        onClick={() => removeFromWatchlist(mediaProps.id)}
                    >
                        <Tooltip title="Remove from Watchlist">
                            <WatchLaterIcon />
                        </Tooltip>
                    </button>
                </a>
            )
        } else {
            return (
                <a>
                    <button
                        className="actions-button"
                        onClick={() => addToWatchlist(mediaProps)}
                    >
                        <Tooltip title="Add to Watchlist">
                            <WatchLaterOutlinedIcon />
                        </Tooltip>
                    </button>
                </a>
            )
        }
    } else {
        return (
            <Tooltip title="Sign in with Google to create and share media watchlists with friends">
                <a
                    onClick={() => setReactGAClickEvent("add to watchlist attempt")}
                >
                    <button
                        className="actions-button"
                        onClick={signInWithGoogle}
                    >
                            <WatchLaterOutlinedIcon />
                    </button>
                </a>
            </Tooltip>
        )
    }
}

export default AddToWatchlist