import React from 'react'

class WatchProviders extends React.Component {
    constructor(props){
        super(props)
    }
    render(){
        const {watchProviders, homepage} = this.props 
        // let buyProviders = !!watchProviders.buy ? watchProviders.buy : []
        // let rentProviders = !!watchProviders.rent ? watchProviders.rent : []
        let streamProviders = !!watchProviders.flatrate ? watchProviders.flatrate : []
        // let freeProviders = !!watchProviders.free ? watchProviders.free : []
        let streamLink = {}

        if(homepage){
            if(homepage.includes("amazon")){
                streamLink['provider'] = "amazon"
            } else if (homepage.includes("hulu")){
                streamLink['provider'] = "hulu"
            } else if(homepage.includes("netflix")){
                streamLink['provider'] = "netflix"
            } else if(homepage.includes("disney")){
                streamLink['provider'] = "disney"
            }
            streamLink['link'] = homepage
        }

        return (
        <div className='media-page-watch-providers'>
            {/* <h4>Where to Watch</h4> */}
            <h4>Where to Stream</h4>
            {/* {!buyProviders.length && !rentProviders.length && !streamProviders.length && !freeProviders.length
                ? <h5 style={{fontWeight: 400}}>Current unavailable in your region</h5>
                : null
            } */}
            {!streamProviders.length
                ? <h5 style={{fontWeight: 400}}>Current unavailable in your region</h5>
                : null
            }
            {streamProviders.length
                ? <div className='stream-providers'>
                    {/* <h5>Stream</h5> */}
                    {console.log('watchProviders', watchProviders.flatrate)}
                    <ul className = 'watch-providers'>
                        {streamProviders.map(provider =>
                            <li>
                                <a href={(streamLink.provider && (streamLink.provider === 'disney' || streamLink.provider === 'amazon' || streamLink.provider === 'hulu' || streamLink.provider === 'netflix')) ? streamLink.link : watchProviders.link} target="_blank">
                                    <img src={`https://image.tmdb.org/t/p/w45${provider.logo_path}`} />
                                </a>
                            </li>
                        )}
                    </ul>
                </div>
                : null
            }
            {/* {freeProviders.length
                ? <div className='stream-ads-providers'>
                    <h5>Stream with Ads</h5>
                    <ul className = 'watch-providers'>
                        {freeProviders.map(provider =>
                            <li>
                            <a href={watchProviders.link} target="_blank">
                                    <img src={`https://image.tmdb.org/t/p/w45${provider.logo_path}`} />
                                </a>
                            </li>
                        )}
                    </ul>
                </div>
                : null
            }
            {buyProviders.length
                ? <div className='buy-providers'>
                    <h5>Buy</h5>
                    <ul className = 'watch-providers'>
                        {buyProviders.map(provider =>
                            <li>
                                <a href={watchProviders.link} target="_blank">
                                    <img src={`https://image.tmdb.org/t/p/w45${provider.logo_path}`} />
                                </a>
                            </li>
                        )}
                    </ul>
                </div>
                : null
            }
            {rentProviders.length
                ? <div className='rent-providers'>
                    <h5>Rent</h5>
                    <ul className = 'watch-providers'>
                        {rentProviders.map(provider =>
                            <li>
                                <a href={watchProviders.link} target="_blank">
                                    <img src={`https://image.tmdb.org/t/p/w45${provider.logo_path}`} />
                                </a>
                            </li>
                        )}
                    </ul>
                </div>
                : null
            } */}
        </div>
    )}
}

export default WatchProviders