import React from 'react'
import { Link } from "react-router-dom"

import DesktopAppBar from '../desktop-app-bar/desktop-app-bar.component'
// import MobileAppBar from '../mobile-app-bar/mobile-app-bar.component'
import logo from '../../assets/logo.png'
import SearchBar from '../search-bar/search-bar.component'
import UserMenu from '../user-menu/UserMenu'

import { signInWithGoogle } from '../../utils/firebaseUtils'
import { Tooltip } from '@material-ui/core';

import './navigation-bar.styles.scss'

class NavigationBar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentUser: this.props.currentUser
        }
    }

    componentDidMount() {
        this.setState({ currentUser: this.props.currentUser })
    }

    componentDidUpdate(prevProps) {
        // console.log('this.props.currentUser, prevProps.currentUser', this.props.currentUser, prevProps.currentUser, this.props.currentUser!==prevProps.currentUser)
        return prevProps.currentUser !== this.props.currentUser
            ? this.setState({ currentUser: this.props.currentUser })
            : null
    }

    shouldComponentUpdate(nextState) {
        // console.log('this.state.currentUser, nextState.currentUser', this.state.currentUser, nextState.currentUser, !!nextState.currentUser, this.state.currentUser===nextState.currentUser)
        if (!!this.state.currentUser && !!nextState.currentUser && this.state.currentUser === nextState.currentUser) {
            return false
        }
        else { return true }
    }

    render() {
        const { handleChange, handleSearch, handleKeyPress, onSignOut, setReactGAClickEvent } = this.props
        const { currentUser } = this.state

        return (
            <div>
                <DesktopAppBar>
                    <div className='navigation-bar'>
                        <Link
                            className='home-link'
                            to="/" >
                            <img
                                src={logo}
                                height='60px'
                                alt={''}
                                className=''
                            />
                            <h1 style={{}}>SchlockMarket</h1>
                        </Link>
                        <div className='main-nav'>
                            <div className='movie-search'>
                                <SearchBar
                                    handleChange={handleChange}
                                    handleSearch={handleSearch}
                                    handleKeyPress={handleKeyPress}
                                />
                            </div>
                            {currentUser
                                ? <div className="sign-out">
                                    <UserMenu
                                        userImageURL={currentUser.photoURL}
                                        setReactGAClickEvent={setReactGAClickEvent}
                                        signOut={onSignOut}
                                    />
                                </div>
                                : <Tooltip title="Sign in with Google to create and share media watchlists with friends">
                                    <a
                                        onClick={() => setReactGAClickEvent("sign in")}
                                    >
                                        <button
                                            className="nav-button"
                                            onClick={signInWithGoogle}
                                        >Sign In</button>
                                    </a>
                                </Tooltip>}
                        </div>
                    </div>

                </DesktopAppBar>
                {/* <MobileAppBar>
            <div className='navigation-bar'>
                <Link 
                    className='home-link' 
                    to="/" >
                        <img
                            src={logo}
                            height='60px'
                            alt={''}
                            className=''
                        />
                        <h1 style={{marginLeft: '5px'}}>SchlockMarket</h1>
                </Link>
                <div className='movie-search'>
                    <SearchBar  
                        handleChange={handleChange} 
                        handleSearch={handleSearch}
                        handleKeyPress={handleKeyPress}
                    />
                </div>
                {/* <div className='sign-in-link'>
                    Sign In
                </div> */}
                {/* </div>

        </MobileAppBar>  */}
            </div>
        );
    }
}

export default NavigationBar
