import React from 'react'
import { Link } from "react-router-dom"

import './footer.scss'

const Footer = ({ onContactClick }) => {
    return (
        <div id="footer">
            <hr className="footer-hr"/>
            <div className="copyright">
                <a
                    href="mailto:schlockmarket@gmail.com?subject=Regarding SchlockMarket"
                    style={{textAlign: "center", color: "hsl(205, 70%, 17%)", display: "block", fontWeight: "400", cursor: "pointer"}}
                    onClick={onContactClick}
                >
                    <span style={{color: "hsl(205, 70%, 17%)"}}>

                    Contact
                    </span>
                </a>
                © SchlockMarket 2020. Media 
                <Link to="/about/media-data">
                    <span style={{fontWeight: "400", cursor: "pointer"}}> data </span>
                </Link> 
                provided by 
                <a href="https://www.themoviedb.org/" target="_blank">
                    <span style={{fontWeight: "400", cursor: "pointer"}}>TMDb</span>
                </a>
            </div>
        </div>
    )
}

export default Footer