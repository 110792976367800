import React from 'react';
import { withRouter, Link } from 'react-router-dom'

import placeholderPoster from '../../assets/placeholderPoster.svg'

import './media-page.styles.scss'

import AddToWatchlist from '../../components/action-buttons/AddToWatchlistButton'
import LikeButton from '../../components/action-buttons/LikeButton'
import WatchedButton from '../../components/action-buttons/WatchedButton'
import WatchProviders from '../../components/where-to-watch/WatchProviders'
import WatchProvidersUtelly from '../../components/where-to-watch/WatchProvidersUtelly'
import VideoCarousel from '../../components/video-carousel/VideoCarousel'

class MediaPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            credits: {},
            moviePrimaryInfo: {},
            watchProviders: {},
            videos: {}
        }

    }
    componentDidMount = async () => {
        let apiKey = 'fec83ea781162c69e785adf8bde87917'
        const { title, type: mediaType } = this.props.match.params
        let mediaId;
        let searchTitle = title.slice(0, title.length - 5)
        if (!this.props.location.state) {
            const nameSearchResponse = await fetch(`https://api.themoviedb.org/3/search/${mediaType}?api_key=${apiKey}&language=en-US&query=${searchTitle}&page=1&include_adult=false`)
            const nameSearchInfo = await nameSearchResponse.json()
            const nameSearchInfoResults = nameSearchInfo.results
            const deDupedResults = this.props.appendDateToMediaObj(nameSearchInfoResults)
            const basicInfo = deDupedResults.filter(film => (film.appendedTitle === title))[0]
            const { id } = basicInfo
            mediaId = id
        } else {
            const { id } = this.props.location.state
            mediaId = id
        }
        const moviePrimaryInfoResponse = await fetch(`https://api.themoviedb.org/3/${mediaType}/${mediaId}?api_key=${apiKey}&language=en-US`)
        const moviePrimaryInfo = await moviePrimaryInfoResponse.json()
        const creditsResponse = await fetch(`https://api.themoviedb.org/3/${mediaType}/${mediaId}/credits?api_key=${apiKey}`)
        const credits = await creditsResponse.json()
        const watchProviders = await this.props.getWatchProviders(mediaId, mediaType)
        const videosResponse = await fetch(`https://api.themoviedb.org/3/movie/${mediaId}/videos?api_key=${apiKey}&language=en-US`)
        const videos = await videosResponse.json()
        const watchProvidersUtelly = await this.props.getWatchProvidersUtelly(moviePrimaryInfo.imdb_id)
        
        this.setState({
            credits: {
                ...credits
            },
            moviePrimaryInfo: {
                ...moviePrimaryInfo
            },
            videos: {
                ...videos
            },
            watchProviders: {
                ...watchProviders
            },
            watchProvidersUtelly: {
                ...watchProvidersUtelly
            }
        })
    }

    render() {
        const { credits, moviePrimaryInfo, videos, watchProviders, watchProvidersUtelly } = this.state
        const {
            addToLikedList,
            addToWatchlist,
            addToWatchedList,
            appendDateToMediaObj,
            currentUser,
            removeFromLikedList,
            removeFromWatchlist,
            removeFromWatchedList,
            setReactGAClickEvent,
            setSelectedEntityId
        } = this.props
        const moviePosterPath = moviePrimaryInfo.poster_path ? `https://image.tmdb.org/t/p/w500/${moviePrimaryInfo.poster_path}` : placeholderPoster
        const movieBackdropPath = `https://image.tmdb.org/t/p/w1280/${moviePrimaryInfo.backdrop_path}`
        const { title, overview, tagline, name, runtime, release_date, first_air_date, homepage } = moviePrimaryInfo
        const { type: mediaType } = this.props.match.params
        let cast = !!credits.cast ? credits.cast : []
        let crew = !!credits.crew ? credits.crew : []
        let directors = crew.length ? credits.crew.filter(crewMember => crewMember.job == "Director") : []
        let writers = crew.length ? credits.crew.filter(crewMember => crewMember.department == "Writing") : []
        let mediaProps = { ...appendDateToMediaObj([moviePrimaryInfo])[0], mediaType, watchProviders }

        if (!!moviePrimaryInfo) {
            return (
                <div className='media-page'>
                    {moviePrimaryInfo.backdrop_path
                        ?
                        <div className='media-page-background-container'>
                            <div
                                className='media-page-background'
                                style={{ backgroundImage: `url(${movieBackdropPath})` }}
                            ></div>
                        </div>
                        : null
                    }
                    <div className="media-page-movie-content">
                        <div className='media-page-poster-and-actions-container'>
                            <div className='media-page-poster-container'>
                                <img
                                    className='media-page-poster'
                                    src={moviePosterPath}
                                />
                            </div>
                            <div
                                className="media-page-actions"
                            >
                                <AddToWatchlist
                                    addToWatchlist={addToWatchlist}
                                    currentUser={currentUser}
                                    mediaProps={mediaProps}
                                    removeFromWatchlist={removeFromWatchlist}
                                    setReactGAClickEvent={setReactGAClickEvent}
                                />
                                <LikeButton
                                    addToLikedList={addToLikedList}
                                    currentUser={currentUser}
                                    mediaProps={mediaProps}
                                    removeFromLikedList={removeFromLikedList}
                                    setReactGAClickEvent={setReactGAClickEvent}
                                />
                                <WatchedButton
                                    addToWatchedList={addToWatchedList}
                                    currentUser={currentUser}
                                    mediaProps={mediaProps}
                                    removeFromWatchedList={removeFromWatchedList}
                                    setReactGAClickEvent={setReactGAClickEvent}
                                />
                            </div>
                            {/* {console.log('hoomepage media page', homepage)}
                            <WatchProviders
                                watchProviders={watchProviders}
                                homepage={homepage}
                            />             */}
                            <WatchProvidersUtelly
                                watchProviders={watchProvidersUtelly}
                                // homepage={homepage}
                            />
                        </div>
                        <div className='media-page-info-container'>
                            <div className='media-page-title'>
                                <h1>{title || name}</h1>
                            </div>
                            <div className='media-page-info'>

                                <div>
                                    <h2 style={{ marginTop: "10px", fontSize: "20px" }}>{tagline}</h2>
                                    <div style={{ marginTop: "10px", marginRight: '15px' }}><span style={{ fontWeight: 700 }}>Release Date: </span>{release_date || first_air_date}</div>
                                    <div style={{ marginTop: "10px" }}><span style={{ fontWeight: 700 }}>Runtime: </span>{runtime} min</div>
                                    <div style={{ marginTop: "10px" }}>{overview}</div>

                                    <div style={{ marginTop: "10px", display: "grid", gridTemplateColumns: "30% 30% 30%", gridColumnGap: "auto" }}>

                                        {cast.length
                                            ? <div className="crew">
                                                <span style={{ fontWeight: 'bold' }}>Cast</span>
                                                {cast.slice(0, 5).map(actor =>
                                                    <Link
                                                        key={actor.id}
                                                        to={`/person/${actor.name}`}
                                                        style={{ textDecoration: 'none', color: 'lightgrey' }}
                                                    >
                                                        <div
                                                            onClick={() => setSelectedEntityId(actor.id, 'person')}
                                                            key={actor.id}
                                                        >
                                                            {actor.name}
                                                        </div>
                                                    </Link>
                                                )}
                                            </div>
                                            : null
                                        }
                                        {writers.length
                                            ? <div className="crew">
                                                <span style={{ fontWeight: 'bold' }}>Writers</span>
                                                {writers.map(writer =>
                                                    <Link
                                                        key={writer.id}
                                                        to={`/person/${writer.name}`}
                                                        style={{ textDecoration: 'none', color: 'lightgrey' }}
                                                    >
                                                        <div
                                                            onClick={() => setSelectedEntityId(writer.id, 'person')}
                                                            key={writer.id}
                                                        >
                                                            {writer.name}
                                                        </div>
                                                    </Link>
                                                )}
                                            </div>
                                            : null
                                        }
                                        {directors.length ?
                                            <div className="crew">
                                                <span style={{ fontWeight: 'bold' }}>Director</span>
                                                {directors.map(director =>
                                                    <Link
                                                        key={director.id}
                                                        to={`/person/${director.name}`}
                                                        style={{ textDecoration: 'none', color: 'lightgrey' }}
                                                    >
                                                        <div
                                                            key={director.id}
                                                            onClick={() => setSelectedEntityId(director.id, 'person')}
                                                        >
                                                            {director.name}
                                                        </div>
                                                    </Link>
                                                )}
                                            </div>
                                            : null
                                        }
                                    </div>
                                        {videos.results && videos.results.length
                                            ? <div style={{ width: '90%' , marginTop: '50px'}} className='videos'>
                                                <h4>Videos</h4>
                                                <VideoCarousel
                                                    videos={videos}
                                                    setReactGAClickEvent={setReactGAClickEvent}
                                                />
                                            </div>
                                            : null
                                        }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }
}

export default withRouter(MediaPage)