import React from 'react';

import tmdbLogo from '../../assets/tmdb_alt_short_blue_logo.svg'
import './tmdb-page.scss'

const TMDBPage = () => {
    return (
        <div className='tmdb-page'>
            <div className="tmdb-info">
                <h1>Media data</h1>
                <p>
                    All media-related metadata used in SchlockMarket, 
                    including cast, crew, studio names, synopses, release dates, 
                    trailers and poster art is supplied by&nbsp;
                    <a href="https://www.themoviedb.org/" target="_blank">
                        <span style={{ fontWeight: "400", cursor: "pointer" }}>
                            The Movie Database (TMDb)
                        </span>
                    </a>.
                </p>
                <a href="https://www.themoviedb.org/" target="_blank">

                    <img src = {tmdbLogo}/>     
</a>
                    <p>SchlockMarket uses the TMDb API but is not endorsed or certified by TMDb.</p>

            </div>
            </div>
    )
}
export default TMDBPage