import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Tooltip } from '@material-ui/core';

import AddToWatchlist from '../../components/action-buttons/AddToWatchlistButton'
import LikeButton from '../../components/action-buttons/LikeButton'
import WatchedButton from '../../components/action-buttons/WatchedButton'

import './media-card.styles.scss'

const MediaCard =  ({
    addToLikedList,
    addToWatchlist,
    addToWatchedList, 
    currentUser, 
    media, 
    media_type,
    removeFromLikedList, 
    removeFromWatchlist,
    removeFromWatchedList, 
    setReactGAClickEvent, 
    setSelectedEntityId 
}) => {

    let mediaCardImagePath;
    if((media.media_type==="movie" || media.mediaType==="movie" || media.media_type === "tv" || media.mediaType === "tv") && media.poster_path){
        mediaCardImagePath = `https://image.tmdb.org/t/p/w500/${media.poster_path}` 
    } else if((media.media_type==="person" || media.mediaType==="person" )&& media.profile_path){
        mediaCardImagePath = `https://image.tmdb.org/t/p/w500/${media.profile_path}`     
    } else {
        mediaCardImagePath = null
    }
    
    return(
        <div className="media-card-container">
            <Link 
                to={{
                    pathname: `/${media_type||media.media_type}/${media.appendedTitle}`,
                    state: { ...media, media_type }
                }}
                style={{marginBottom: '5px'}}
                className="media-card-link"
            >
                <Tooltip title={media.overview || media.name}>
                    {mediaCardImagePath ? 
                        <img 
                            className='media-card'
                            src={mediaCardImagePath}
                            onClick={()=>setSelectedEntityId(media.id, media.media_type)}
                            onMouseUp={()=>setSelectedEntityId(media.id, media.media_type)}
                        />
                        : <div className='media-card-placeholder'>
                            <h4>{media.title || media.name}</h4>
                        </div>
                    }
                </Tooltip>
            </Link>

            <div>
                <Link 
                    to={{
                        pathname: `/${media_type||media.media_type}/${media.appendedTitle}`,
                        state: { ...media, media_type }
                    }}
                    style={{marginBottom: '5px', color: "lightgrey"}}
                    className="media-card-link"
                >
                    <p style={{fontSize: '12px', margin: 'auto'}}>{media.title || media.name}</p>            
                </Link>
                <p style={{fontSize: '12px', margin: 'auto'}}>{media.job || media.character}</p>
            {media_type==="person" 
            ? null
            :  media.release_date==="" || media.first_air_date==="" ? <div><br></br></div> :
                <p style={{fontSize: '12px', margin: 'auto'}}>({media.release_date || media.first_air_date})</p>}
                {media_type==="person" 
            ? null
            : 
                <div className="actions">
                    <AddToWatchlist 
                        addToWatchlist = {addToWatchlist}
                        currentUser={currentUser}
                        mediaProps = {media}
                        removeFromWatchlist = {removeFromWatchlist}
                        setReactGAClickEvent={setReactGAClickEvent}
                    />
                    <LikeButton 
                        addToLikedList = {addToLikedList}
                        currentUser={currentUser}
                        mediaProps = {media}
                        removeFromLikedList = {removeFromLikedList}
                        setReactGAClickEvent={setReactGAClickEvent}
                    />
                    <WatchedButton 
                        addToWatchedList = {addToWatchedList}
                        currentUser={currentUser}
                        mediaProps = {media}
                        removeFromWatchedList = {removeFromWatchedList}
                        setReactGAClickEvent={setReactGAClickEvent}
                    />
                </div>}
            </div>
            }
        </div>
    )
}

export default withRouter(MediaCard)