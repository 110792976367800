import React from 'react'
import './action-buttons.scss'

import { signInWithGoogle } from '../../utils/firebaseUtils'
import { Tooltip } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

const LikeButton = ({
    addToLikedList,
    currentUser,
    mediaProps,
    removeFromLikedList,
    setReactGAClickEvent
}) => {
    console.log('add to liked list')
    if (currentUser) {
        if (currentUser.likedList && currentUser.likedList[mediaProps.id]) {
            return (
                <a>
                    <button
                        className="actions-button"
                        onClick={() => removeFromLikedList(mediaProps.id)}
                    >
                             <Tooltip title="Remove from Liked List">
                            <FavoriteIcon />
                        </Tooltip>
                    </button>
                </a>
            )
        } else {
            return (
                <a>
                    <button
                        className="actions-button"
                        onClick={() => addToLikedList(mediaProps)}
                    >
                        <Tooltip title="Add to Liked List">
                            <FavoriteBorderIcon />
                        </Tooltip>
                    </button>
                </a>
            )
        }
    } else {
        return (
            <Tooltip title="Sign in with Google to create and share watchlists, likes, and more with friends">
                <a
                    onClick={() => setReactGAClickEvent("add to liked list attempt")}
                >
                    <button
                        className="actions-button"
                        onClick={signInWithGoogle}
                    >
                        <FavoriteBorderIcon />
                    </button>
                </a>
            </Tooltip>
        )
    }
}

export default LikeButton