import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom'
import ReactGA from 'react-ga';

import NavigationBar from './components/navigation-bar/navigation-bar.component'
import Footer from './components/footer/footer'

import MediaPage from './pages/media-page/media-page.component'
import PersonPage from './pages/person-page/person-page'
import TMDBPage from './pages/tmdb-page/tmdb-page'
import HomePage from './pages/home-page/HomePage'
import SearchResultsPage from './pages/search-results-page/SearchResultsPage'
import UserAccount from './pages/account-page/UserAccount'
//utils
// import localUtils from './utils/localUtils'
import { auth, createUserProfileDocument, setUserData } from './utils/firebaseUtils'

import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentUser: null,
      searchQuery: '',
      searchResults: [],
      defaultList: [],
      selectedMediaId: '',
      selectedPersonId: ''
    }
    ReactGA.initialize('UA-145479778-7');
    ReactGA.pageview(window.location.pathname);
  }

  componentDidMount() {

    this.unsubscribeFromAuth = auth.onAuthStateChanged(async userAuth => {
      if (userAuth) {
        const userRef = createUserProfileDocument(userAuth);

        (await userRef).onSnapshot(async snapShot => {
          this.setState(prevState => ({
            ...prevState,
            currentUser: {
              ...snapShot.data(),
            },
          }));
        })
      } else {
        this.setState({
          currentUser: userAuth
        })
      }
    })
    this.setState((prevState) => ({
      ...prevState,
    }))
  }


  componentWillUnmount() {
    this.unsubscribeFromAuth();
  }

  setReactGAClickEvent = (itemClicked) => {
    ReactGA.event({
      category: 'User',
      action: `Click ${itemClicked}`
    });
  }


  onSignOut = () => {
    auth.signOut()
    localStorage.clear()
    this.setReactGAClickEvent('sign out')
    this.setState(prevState => ({
      ...prevState,
      // [this.taskListTypes[0]]: []
    }))
  }


  setSelectedEntityId = (id, type) => {
    if (type === "person") {
      this.setSelectedPersonId(id);
    } else {
      this.setSelectedMediaId(id)
    }
  }

  setSelectedMediaId = (id) => {
    this.setLocalStorage('selectedMediaId', id)
  }

  setSelectedPersonId = (id) => {
    this.setLocalStorage('selectedPersonId', id)
  }

  setLocalStorage = (key, value) => {
    window.localStorage.setItem(key, JSON.stringify(value))
  }

  getLocalStorage = (key) => {
    const json = window.localStorage.getItem(key)
    return JSON.parse(json)
  }

  handleChange = (event) => {
    this.setSearchQuery(event.target.value)
  }

  handleKeyPress = (event, cb) => {
    if (event.key === 'Enter') {
      cb()
    }
  }

  setSearchQuery = (query) => {
    this.setState({
      searchQuery: query
    }
    )
  }

  getWatchProviders = async(mediaId, mediaType) => {
    const apiKey = 'fec83ea781162c69e785adf8bde87917'
    const region = "US"
    const watchProvidersResponse = await fetch(`https://api.themoviedb.org/3/${mediaType}/${mediaId}/watch/providers?api_key=${apiKey}`)
    const watchProvidersResult = await watchProvidersResponse.json()
    const watchProvidersInRegion = watchProvidersResult.results[region]
    return watchProvidersInRegion
  }

  getWatchProvidersUtelly = async(mediaId, country="us", source="imdb") => {
    const watchProvidersUtellyResponse = await fetch(`https://utelly-tv-shows-and-movies-availability-v1.p.rapidapi.com/idlookup?source_id=${mediaId}&source=${source}&country=${country}`, {
      "method": "GET",
      "headers": {
        "x-rapidapi-key": "2ea5a99a88msh3d364f7dc9cee48p12a22djsnbef74f091b68",
        "x-rapidapi-host": "utelly-tv-shows-and-movies-availability-v1.p.rapidapi.com"
      }
    })    
    const watchProvidersUtellyResult = await watchProvidersUtellyResponse.json()
    console.log('watchProviders2Result', watchProvidersUtellyResult)
    const watchProvidersUtelly = watchProvidersUtellyResult.collection.locations
    return watchProvidersUtelly
  }

  handleSearch = async () => {
    const { searchQuery } = this.state
    if (searchQuery.length === 0) {
      return;
    }
    let apiKey = 'fec83ea781162c69e785adf8bde87917'
    const searchResponsePage1 = await fetch(`https://api.themoviedb.org/3/search/multi?api_key=${apiKey}&language=en-US&query=${searchQuery}&page=1&include_adult=false`)
    const searchResultsPage1 = await searchResponsePage1.json()
    const searchResponsePage2 = await fetch(`https://api.themoviedb.org/3/search/multi?api_key=${apiKey}&language=en-US&query=${searchQuery}&page=2&include_adult=false`)
    const searchResultsPage2 = await searchResponsePage2.json()
    const searchResponsePage3 = await fetch(`https://api.themoviedb.org/3/search/multi?api_key=${apiKey}&language=en-US&query=${searchQuery}&page=3&include_adult=false`)
    const searchResultsPage3 = await searchResponsePage3.json()
    const searchResponsePage4 = await fetch(`https://api.themoviedb.org/3/search/multi?api_key=${apiKey}&language=en-US&query=${searchQuery}&page=4&include_adult=false`)
    const searchResultsPage4 = await searchResponsePage4.json()
    const searchResponsePage5 = await fetch(`https://api.themoviedb.org/3/search/multi?api_key=${apiKey}&language=en-US&query=${searchQuery}&page=5&include_adult=false`)
    const searchResultsPage5 = await searchResponsePage5.json()
    const mediaList = [
      ...searchResultsPage1.results, 
      ...searchResultsPage2.results, 
      ...searchResultsPage3.results, 
      ...searchResultsPage4.results, 
      ...searchResultsPage5.results
    ]
    console.log('mediaList', mediaList)
    const dedupedMediaList = this.dedupeOnMediaId(mediaList)
    const cleanedMediaList = this.appendDateToMediaObj(dedupedMediaList)
    this.setState({ searchResults: cleanedMediaList }, () => this.props.history.push(`/search/${searchQuery}`))
  }

  dedupeOnMediaId = (mediaArray) => {
    const mediaObj = {}
    const dedupedMediaArr = []
    for(let media of mediaArray) {
      if(!mediaObj[media.id]){
        mediaObj[media.id] = true
        dedupedMediaArr.push(media)
      }
    }
    return dedupedMediaArr
  }

  appendDateToMediaObj = (mediaList) => {
    const cleanedList = []
    mediaList.forEach(mediaObj => {
      if (mediaObj.title) {
        mediaObj.release_date ? mediaObj['appendedTitle'] = mediaObj.title + " " + mediaObj.release_date.slice(0, 4) : mediaObj['appendedTitle'] = mediaObj.title + " " + ""
      } else {
        mediaObj.first_air_date ? mediaObj['appendedTitle'] = mediaObj.name + " " + mediaObj.first_air_date.slice(0, 4) : mediaObj['appendedTitle'] = mediaObj.name + " " + ""
      }
      cleanedList.push(mediaObj)
    })
    return cleanedList
  }
  
  onContactClick = () => {
    this.setReactGAClickEvent('contact')
  }
  
  addToWatchlist = (mediaProps) =>{
    console.log('id', mediaProps.id)
    console.log('adding to watchlist')
    this.setReactGAClickEvent('Add to Watchlist')
    this.setState((prevState) => ({
      ...prevState,
      watchlist: {
        ...prevState.currentUser.watchlist,
        [mediaProps.id] : {
          ...mediaProps,
          key: mediaProps.id,
          addedDatetime: new Date(),
        }
      },
    }), () => {
      return this.state.currentUser ? setUserData('watchlist', this.state['watchlist']) : null
    }
    )
  }

  removeFromWatchlist = (mediaId) =>{
    this.setReactGAClickEvent('Remove from to Watchlist')
    let updatedWatchlist = {...this.state.currentUser.watchlist}
    delete updatedWatchlist[mediaId]
    this.setState((prevState) => ({
      ...prevState,
      watchlist: updatedWatchlist,
    }), () => {
      return this.state.currentUser ? setUserData('watchlist', this.state['watchlist']) : null
    }
    )
  }

  addToLikedList = (mediaProps) =>{
    console.log('id', mediaProps.id)
    console.log('adding to liked list')
    this.setReactGAClickEvent('Add to Liked List')
    this.setState((prevState) => ({
      ...prevState,
      likedList: {
        ...prevState.currentUser.likedList,
        [mediaProps.id] : {
          ...mediaProps,
          key: mediaProps.id,
          addedDatetime: new Date(),
        }
      },
    }), () => {
      return this.state.currentUser ? setUserData('likedList', this.state['likedList']) : null
    }
    )
  }

  removeFromLikedList = (mediaId) =>{
    this.setReactGAClickEvent('Remove from to Liked List')
    let updatedLikedList = {...this.state.currentUser.likedList}
    delete updatedLikedList[mediaId]
    this.setState((prevState) => ({
      ...prevState,
      likedList: updatedLikedList,
    }), () => {
      return this.state.currentUser ? setUserData('likedList', this.state['likedList']) : null
    }
    )
  }

  addToWatchedList = (mediaProps) =>{
    console.log('id', mediaProps.id)
    console.log('adding to watched list')
    this.setReactGAClickEvent('Add to Watched List')
    this.setState((prevState) => ({
      ...prevState,
      watchedList: {
        ...prevState.currentUser.watchedList,
        [mediaProps.id] : {
          ...mediaProps,
          key: mediaProps.id,
          addedDatetime: new Date(),
        }
      },
    }), () => {
      return this.state.currentUser ? setUserData('watchedList', this.state['watchedList']) : null
    }
    )
  }

  removeFromWatchedList = (mediaId) =>{
    this.setReactGAClickEvent('Remove from to Watched List')
    let updatedWatchedList = {...this.state.currentUser.watchedList}
    delete updatedWatchedList[mediaId]
    this.setState((prevState) => ({
      ...prevState,
      watchedList: updatedWatchedList,
    }), () => {
      return this.state.currentUser ? setUserData('watchedList', this.state['watchedList']) : null
    }
    )
  }

  render() {
    const { currentUser, searchResults } = this.state
    return (
      <div className="App">
        <NavigationBar
          handleChange={this.handleChange}
          handleSearch={this.handleSearch}
          handleKeyPress={this.handleKeyPress}
          setReactGAClickEvent={this.setReactGAClickEvent}
          onSignOut={this.onSignOut}
          currentUser={currentUser}
        />
        <div className="App-body">
          <Switch>
            <Route
              path='/'
              exact={true}
              render={() => <HomePage
                addToLikedList = {this.addToLikedList}
                addToWatchlist = {this.addToWatchlist}
                addToWatchedList = {this.addToWatchedList}
                currentUser={currentUser}
                removeFromLikedList = {this.removeFromLikedList}
                removeFromWatchlist = {this.removeFromWatchlist} 
                removeFromWatchedList = {this.removeFromWatchedList}
                setSelectedEntityId={this.setSelectedEntityId}
                appendDateToMediaObj={this.appendDateToMediaObj}
                setReactGAClickEvent={this.setReactGAClickEvent}

              />}
            />
            <Route
              path='/search/:searchQuery'
              render={() => <SearchResultsPage
                addToLikedList = {this.addToLikedList}
                addToWatchlist = {this.addToWatchlist}
                addToWatchedList = {this.addToWatchedList}
                currentUser={currentUser}
                removeFromLikedList = {this.removeFromLikedList}
                removeFromWatchlist = {this.removeFromWatchlist} 
                removeFromWatchedList = {this.removeFromWatchedList}
                handleSearch={this.handleSearch}
                mediaList={searchResults}
                setSearchQuery={this.setSearchQuery}
                setSelectedEntityId={this.setSelectedEntityId}
                setReactGAClickEvent={this.setReactGAClickEvent}
              />}
            />
            <Route
              path={'/about/media-data'}
              render={() => <TMDBPage />}
            />            
            <Route
              path={'/person/:name'}
              render={() => <PersonPage
                addToLikedList = {this.addToLikedList}
                addToWatchlist = {this.addToWatchlist}
                addToWatchedList = {this.addToWatchedList}
                currentUser={currentUser}
                removeFromLikedList = {this.removeFromLikedList}
                removeFromWatchlist = {this.removeFromWatchlist} 
                removeFromWatchedList = {this.removeFromWatchedList}
                setSelectedEntityId={this.setSelectedEntityId}
                setReactGAClickEvent={this.setReactGAClickEvent}
                appendDateToMediaObj={this.appendDateToMediaObj}

              />}
            />
            <Route
              path={'/:type/:title'}
              render={() => <MediaPage
                addToLikedList = {this.addToLikedList}
                addToWatchlist = {this.addToWatchlist}
                addToWatchedList = {this.addToWatchedList}
                appendDateToMediaObj={this.appendDateToMediaObj}
                currentUser={currentUser}
                getLocalStorage={this.getLocalStorage}
                getWatchProviders = {this.getWatchProviders}
                getWatchProvidersUtelly = {this.getWatchProvidersUtelly}
                removeFromLikedList = {this.removeFromLikedList}
                removeFromWatchlist = {this.removeFromWatchlist} 
                removeFromWatchedList = {this.removeFromWatchedList}
                setReactGAClickEvent={this.setReactGAClickEvent}
                setSelectedEntityId={this.setSelectedEntityId}
              />}
            />
            <Route
              path='/myAccount'
              render={() => <UserAccount 
                addToLikedList = {this.addToLikedList}
                addToWatchlist = {this.addToWatchlist}
                addToWatchedList = {this.addToWatchedList}
                currentUser={currentUser}
                removeFromLikedList = {this.removeFromLikedList}
                removeFromWatchlist = {this.removeFromWatchlist} 
                removeFromWatchedList = {this.removeFromWatchedList}
                setSelectedEntityId={this.setSelectedEntityId}
                setReactGAClickEvent = {this.setReactGAClickEvent}
              />}
            />

          </Switch>

        </div>
        <Footer 
          onContactClick={this.onContactClick}
        />
      </div>
    )
  };
}

export default withRouter(App);
