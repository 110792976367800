import React from 'react'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import './VideoCarousel.scss'

class VideoCarousel extends React.Component{
    constructor(props){
        super(props)
    }

    onRightClick=(e)=>{
        e.persist()
        this.props.setReactGAClickEvent('chev right')
        e.currentTarget.parentNode.parentNode.childNodes[1].scrollLeft+=480
    }

    onLeftClick=(e)=>{
        this.props.setReactGAClickEvent('chev left')
        e.currentTarget.parentNode.parentNode.childNodes[1].scrollLeft-=480
    }

    render(){
        const {
            videos
        } = this.props
        return (
            <div style={{width: '100%'}} className="carousel-wrap video-carousel-horizontal">
                <ul className="carousel-nav">
                    <li 
                        className="video-carousel-prev" 
                        onClick={e=>this.onLeftClick(e)}
                    >
                        <a className="carousel">
                            <ChevronLeftIcon className="carousel-chevron" />
                        </a>
                    </li>
                    <li 
                        className="video-carousel-next" 
                        onClick={e=>this.onRightClick(e)}
                    >
                        <a className="carousel">
                            <ChevronRightIcon className="carousel-chevron" />
                        </a>
                    </li>
                </ul>
            <div className='carousel-mask'>
                <ul 
                    className="carousel" 
                    style={{width: `${videos.results.length*480}px`}}
                >
                {
                    !videos ? null : videos.results.map(video =>
                        <li 
                            className="list-item"
                            key={video.id}  
                        >
                            <iframe
                                width={470}
                                height={261} 
                                title={video.name}
                                loading="lazy"
                                src={`https://www.youtube.com/embed/${video.key}?enablejsapi=1&autoplay=0`} 
                            />
                        </li>
                    )
                }
                </ul>
            </div>
            </div>
        )
    }
}

export default VideoCarousel