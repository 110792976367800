import React from 'react'
import { Input } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import './search-bar.styles.scss'



class SearchBar extends React.Component {
    constructor(props){
        super(props)

    }

    render(){
        const { handleChange, handleKeyPress, handleSearch } = this.props
        return(
            <div className='search-bar'
            style={{
                color:"white",
                backgroundColor: "#2c3440",
                borderRadius: "15px",
                paddingLeft: "5px",
                textAlign: "center",
                paddingRight: "10px",
                display: "flex",
                alignItems: "center",
                width: "200px"
            }}>
                <SearchIcon 
                    style={{
                        color: "white",
                        marginRight: "10px"
                    }} 
                    onClick={handleSearch}
                />
                <Input 
                    type='search'
                    placeholder='Search...'
                    onChange={(event)=>handleChange(event)}
                    onKeyPress={(event)=>handleKeyPress(event, handleSearch)}
                    style={{
                        color:"white",
                    }}
                    disableUnderline={true}
                />
            </div>
        )
    }

}

export default SearchBar