import React from 'react';
import MediaCardlist from '../../components/media-cardlist/media-cardlist.component';

import './HomePage.scss'

class HomePage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            movie: undefined,
            dailyTrendingMovies: [],
            dailyTrendingTV: [],
            moviePrimaryInfo: {}
        }

    }
    componentDidMount = async () => {
        let apiKey = 'fec83ea781162c69e785adf8bde87917'
        const dailyTrendingMoviesResponse = await fetch(`https://api.themoviedb.org/3/trending/movie/day?api_key=${apiKey}`)
        const dailyTrendingMoviesInfo = await dailyTrendingMoviesResponse.json()
        // console.log('dailyTrendingMovies', dailyTrendingMoviesInfo, dailyTrendingMoviesInfo.results)
        const dailyTrendingTVResponse = await fetch(`https://api.themoviedb.org/3/trending/tv/day?api_key=${apiKey}`)
        const dailyTrendingTVInfo = await dailyTrendingTVResponse.json()
        // console.log('dailyTrendingTV', dailyTrendingTVInfo, dailyTrendingTVInfo.results)

        this.setState({ 
            dailyTrendingMovies: [...this.props.appendDateToMediaObj(dailyTrendingMoviesInfo.results).slice(0,16)],
            dailyTrendingTV: [...this.props.appendDateToMediaObj(dailyTrendingTVInfo.results).slice(0,16)]
        })
    }

    render() {
        const {
            addToLikedList,
            addToWatchlist, 
            addToWatchedList,
            currentUser, 
            removeFromLikedList,
            removeFromWatchlist,
            removeFromWatchedList, 
            setReactGAClickEvent, 
            setSelectedEntityId
        } = this.props
        return(
            <div className='home-page'>
                <div id="popular">
                    <h2>Popular Movies Today</h2>
                    <hr />
                    <MediaCardlist 
                        addToLikedList = {addToLikedList}
                        addToWatchlist = {addToWatchlist} 
                        addToWatchedList = {addToWatchedList} 
                        currentUser={currentUser}
                        removeFromLikedList = {removeFromLikedList}
                        removeFromWatchlist = {removeFromWatchlist} 
                        removeFromWatchedList = {removeFromWatchedList} 
                        mediaList={this.state.dailyTrendingMovies} 
                        media_type="movie"
                        setSelectedEntityId={setSelectedEntityId}
                        setReactGAClickEvent={setReactGAClickEvent}
                    />
                </div>
                <div id="popular">
                    <h2>Popular TV Today</h2>
                    <hr />
                    <MediaCardlist 
                        addToLikedList = {addToLikedList}
                        addToWatchlist = {addToWatchlist} 
                        addToWatchedList = {addToWatchedList} 
                        currentUser={currentUser}
                        removeFromLikedList = {removeFromLikedList}
                        removeFromWatchlist = {removeFromWatchlist} 
                        removeFromWatchedList = {removeFromWatchedList} 
                        mediaList={this.state.dailyTrendingTV} 
                        media_type="tv"
                        setSelectedEntityId={setSelectedEntityId}
                        setReactGAClickEvent={setReactGAClickEvent}
                    />
                </div>
            </div>
        )           
    }
}

export default HomePage