import React from 'react'

class WatchProvidersUtelly extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        const { watchProviders } = this.props
        console.log('watchparvodiersl;kasjdf', watchProviders)
        const watchProvidersArray = watchProviders ? Object.values(watchProviders) : []
        if (watchProvidersArray.length) {
            return (
                <div className='media-page-watch-providers'>
                    <h4>Where to Stream</h4>
                    <ul style={{ padding: 0 }} className='watch-providers'>
                        {watchProvidersArray.map(provider =>
                            <a href={provider.url} target="_blank"
                                style={{ borderRadius: '4px', width: '100px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', margin: '5px 5px 0 0' }}
                            >
                                <li
                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                >
                                    <img src={provider.icon} style={{ width: '70px' }} />
                                </li>
                            </a>
                        )}
                    </ul>
                </div>
            )
        }
        else { return null }
    }
}

export default WatchProvidersUtelly