import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'

//for globally styling "permanent" desktop and mobile app bars

const useStyles = makeStyles(theme => ({
  desktopAppBar: {
    position: 'fixed',
    alignItems: 'center',
    width: '100%',
    height: '60px',
    top: '0px',
    zIndex: '10',
    backgroundColor: 'rgba(0, 0, 0, 0.842)',
    //breakpoints.up means above (excusive), .down means below (inclusive)
    // [theme.breakpoints.down('xs')]: {
    //   display: 'none',
    // }
  },
}))

const DesktopAppBar = (props) => {
  const classes = useStyles()
  const { children } = props

  return (
    <div className={classes.root}>

      <AppBar position="static" className={classes.desktopAppBar}>
        {children}
      </AppBar>
     </div>
  )
}

export default DesktopAppBar