import React from 'react'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import MediaCard from '../media-card/media-card.component'

import './media-cardlist.styles.scss'

class MediaCardList extends React.Component{
    constructor(props){
        super(props)
        this.state={
        }
    }

    onRightClick=(e)=>{
        e.persist()
        this.props.setReactGAClickEvent('chev right')
        e.currentTarget.parentNode.parentNode.childNodes[1].scrollLeft+=960
    }

    onLeftClick=(e)=>{
        this.props.setReactGAClickEvent('chev left')
        e.currentTarget.parentNode.parentNode.childNodes[1].scrollLeft-=960
    }

    render(){
        const {
            addToLikedList,
            addToWatchlist, 
            addToWatchedList,
            currentUser,
            mediaList, 
            media_type, 
            removeFromLikedList,
            removeFromWatchlist,
            removeFromWatchedList, 
            setReactGAClickEvent, 
            setSelectedEntityId
        } = this.props
        return (
            <div className="carousel-wrap carousel-horizontal">
                <ul className="carousel-nav">
                    <li 
                        className="carousel-prev" 
                        onClick={e=>this.onLeftClick(e)}
                    >
                        <a className="carousel">
                            <ChevronLeftIcon className="carousel-chevron" />
                        </a>
                    </li>
                    <li 
                        className="carousel-next" 
                        onClick={e=>this.onRightClick(e)}
                    >
                        <a className="carousel">
                            <ChevronRightIcon className="carousel-chevron" />
                        </a>
                    </li>
                </ul>
            <div className='carousel-mask'>
                <ul 
                    className="carousel" 
                    style={{width: `${mediaList.length*240}px`}}
                >
                {
                    !mediaList ? null : mediaList.map(media =>
                        <li 
                            className="list-item"
                            key={media.id }     
                        >
                            <MediaCard 
                                key={media.id } 
                                addToLikedList = {addToLikedList}
                                addToWatchlist = {addToWatchlist} 
                                addToWatchedList = {addToWatchedList} 
                                currentUser={currentUser}
                                removeFromLikedList = {removeFromLikedList}
                                removeFromWatchlist = {removeFromWatchlist} 
                                removeFromWatchedList = {removeFromWatchedList} 
                                media={media} 
                                media_type={media_type || media.media_type || media.mediaType}
                                setSelectedEntityId={setSelectedEntityId}
                                setReactGAClickEvent={setReactGAClickEvent}
                            />
                        </li>
                    )
                }
                </ul>
            </div>
            </div>
        )
    }
}

export default MediaCardList