import React from 'react';
import { withRouter } from 'react-router-dom'

import MediaCardList from '../../components/media-cardlist/media-cardlist.component'

import './person-page.scss'

class PersonPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            personInfo: {},
            combinedCredits: {}
        }
    }
    componentDidMount = async () => {
        let apiKey = 'fec83ea781162c69e785adf8bde87917'
        const { name } = this.props.match.params
        let mediaId;
        if(!this.props.location.state){
            const nameSearchResponse = await fetch(`https://api.themoviedb.org/3/search/person?api_key=${apiKey}&language=en-US&query=${name}&page=1&include_adult=false`)
            const nameSearchInfo = await nameSearchResponse.json()
            const nameSearchInfoResults = nameSearchInfo.results
            const { id } = nameSearchInfoResults[0]
            mediaId=id
        } else{
            const { id } = this.props.location.state
            mediaId=id
        }
        const personInfoResponse = await fetch(`https://api.themoviedb.org/3/person/${mediaId}?api_key=${apiKey}&language=en-US`)
        const personInfo = await personInfoResponse.json()

        const combinedCreditsResponse = await fetch(`https://api.themoviedb.org/3/person/${mediaId}/combined_credits?api_key=${apiKey}&language=en-US`)
        const combinedCredits = await combinedCreditsResponse.json()

        this.setState({ 
            personInfo: {
                ...personInfo
            },
            combinedCredits: {
                ...combinedCredits
            }
        })
    }

    render() {
        //check if crew and cast exist
        const { personInfo, combinedCredits } = this.state
        const { 
            profile_path: profilePath, 
            biography, 
            place_of_birth: placeOfBirth, 
            name, 
            known_for_department: primaryDepartment
        } = personInfo
        let cast = !!combinedCredits.cast ? combinedCredits.cast : []
        let crew = !!combinedCredits.crew ? combinedCredits.crew : []
        let primaryCredits = primaryDepartment==="Acting" ? cast : crew
        let secondaryCredits = primaryDepartment==="Acting" ? crew: cast
        let primaryCreditsHeader = primaryDepartment==="Acting" ? "Appeared In" : "Worked On"
        let secondaryCreditsHeader = primaryDepartment==="Acting" ? "Worked On" : "Appeared In"
        const { 
            addToLikedList,
            addToWatchlist, 
            addToWatchedList,
            currentUser, 
            removeFromLikedList,
            removeFromWatchlist,
            removeFromWatchedList, 
            setReactGAClickEvent, 
            setSelectedEntityId 
        } = this.props
        const bioText = biography || 'Not currently provided'
        const personProfilePath = `https://image.tmdb.org/t/p/w500/${profilePath}`
            return (
                <div className='person-page'>
                    <div 
                        className="person-page-movie-content"
                        style={{paddingLeft: profilePath? 10 : 0}}
                    >
                    {profilePath ? <div className='person-page-poster-container'>

                            <img
                                className='person-page-poster'
                                src={personProfilePath}
                            /> 
                        </div>: null}
                   <div className='person-page-info-container'>
                        <div className='person-page-title'>
                            <h1>{name}</h1>
                        </div>
                        <div className='person-page-info'>
                            
                            <div>
                                <h2 style={{marginTop: "10px", fontSize: "20px"}}>{placeOfBirth}</h2>
                                <div style={{marginTop: "10px"}}>{bioText}</div>
                            </div>
                        </div>
                    
                        </div>
                    </div>
                <div className="person-credits">
                {primaryCredits.length 
                    ?<div className='home-page'>
                        <div id="popular">
                        <h3 style={{color: 'white', marginBottom: 0, paddingBottom: 0}}>{primaryCreditsHeader}</h3>
                        <MediaCardList
                            addToLikedList = {addToLikedList}
                            addToWatchlist = {addToWatchlist} 
                            addToWatchedList = {addToWatchedList} 
                            currentUser={currentUser}
                            removeFromLikedList = {removeFromLikedList}
                            removeFromWatchlist = {removeFromWatchlist} 
                            removeFromWatchedList = {removeFromWatchedList} 
                            mediaList={this.props.appendDateToMediaObj(primaryCredits.slice(0,16))} 
                            setSelectedEntityId={setSelectedEntityId}
                            setReactGAClickEvent={setReactGAClickEvent}
                        />     

                        </div>
                    </div>
                    : null
                }
                    
                {secondaryCredits.length 
                    ?<div className='home-page'>
                        <div id="popular">
                        <h3 style={{color: 'white', marginBottom: 0, paddingBottom: 0}}>{secondaryCreditsHeader}</h3>
                        <MediaCardList 
                            addToLikedList = {addToLikedList}
                            addToWatchlist = {addToWatchlist} 
                            addToWatchedList = {addToWatchedList} 
                            currentUser={currentUser}
                            removeFromLikedList = {removeFromLikedList}
                            removeFromWatchlist = {removeFromWatchlist} 
                            removeFromWatchedList = {removeFromWatchedList} 
                            mediaList={this.props.appendDateToMediaObj(secondaryCredits.slice(0,16))} 
                            setSelectedEntityId={setSelectedEntityId}
                            setReactGAClickEvent={setReactGAClickEvent}
                        />     

                        </div>
                    </div>
                    : null
                }

                </div>      
        </div>
    )}
}

export default withRouter(PersonPage)