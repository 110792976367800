import React from 'react';
import { withRouter } from 'react-router-dom'

import MediaCardlist from '../../components/media-cardlist/media-cardlist.component';

import './SearchResultsPage.scss'

class SearchResultsPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }

    }
    componentDidMount = async () => {
        if(this.props.mediaList.length!==0){
            return;
        } else {
            await this.props.setSearchQuery(this.props.match.params.searchQuery)
            this.props.handleSearch()
        }
    }

    render() {
        const {
            addToLikedList,
            addToWatchlist, 
            addToWatchedList,
            currentUser, 
            removeFromLikedList,
            removeFromWatchlist,
            removeFromWatchedList, 
            setReactGAClickEvent,
            setSelectedEntityId
        } = this.props
        const moviesList = this.props.mediaList.filter(media=>media.media_type==="movie")
        const tvList = this.props.mediaList.filter(media=>media.media_type==="tv")
        const peopleList = this.props.mediaList.filter(media=>media.media_type==="person")
        return(
            <div className='search-results-page'>
                {moviesList.length 
                    ? <div id="popular">
                        <h2>Movies</h2>
                        <hr />
                        <MediaCardlist 
                            addToLikedList = {addToLikedList}
                            addToWatchlist = {addToWatchlist} 
                            addToWatchedList = {addToWatchedList} 
                            currentUser={currentUser}
                            removeFromLikedList = {removeFromLikedList}
                            removeFromWatchlist = {removeFromWatchlist} 
                            removeFromWatchedList = {removeFromWatchedList} 
                            mediaList={moviesList}
                            setSelectedEntityId={this.props.setSelectedEntityId}
                            setReactGAClickEvent={this.props.setReactGAClickEvent}
                        />
                    </div>
                    : null
                }
                {tvList.length
                    ? <div id="popular">
                        <h2>TV</h2>
                        <hr />
                        <MediaCardlist 
                            addToLikedList = {addToLikedList}
                            addToWatchlist = {addToWatchlist} 
                            addToWatchedList = {addToWatchedList} 
                            currentUser={currentUser}
                            removeFromLikedList = {removeFromLikedList}
                            removeFromWatchlist = {removeFromWatchlist} 
                            removeFromWatchedList = {removeFromWatchedList} 
                            mediaList={tvList}
                            setSelectedEntityId={setSelectedEntityId}
                            setReactGAClickEvent={setReactGAClickEvent}
                        />
                    </div>
                    : null
                }
                {peopleList.length
                    ? <div id="popular">
                        <h2>People</h2>
                        <hr />
                        <MediaCardlist 
                            currentUser={currentUser}
                            mediaList={peopleList}
                            setSelectedEntityId={setSelectedEntityId}
                            setReactGAClickEvent={setReactGAClickEvent}
                        />
                    </div>
                    : null
                }
            </div>
        )           
    }
}

export default withRouter(SearchResultsPage)