import React from 'react';

import MediaCardList from '../../components/media-cardlist/media-cardlist.component'

import './UserAccount.scss'

const UserAccount = ({
    addToLikedList,
    addToWatchlist, 
    addToWatchedList,
    currentUser, 
    removeFromLikedList,
    removeFromWatchlist,
    removeFromWatchedList, 
    setReactGAClickEvent,
    setSelectedEntityId
}) => {

    let watchlistArray = (currentUser && currentUser.watchlist) ? Object.values(currentUser.watchlist) : []
    const watchMoviesList = watchlistArray.filter(media=>media.mediaType==="movie" || media.media_type==="movie")
    const watchTvList = watchlistArray.filter(media=>media.mediaType==="tv" || media.media_type==="tv")
    
    let likedListArray = (currentUser && currentUser.likedList) ? Object.values(currentUser.likedList) : []
    const likedMoviesList = likedListArray.filter(media=>media.mediaType==="movie" || media.media_type==="movie")
    const likedTvList = likedListArray.filter(media=>media.mediaType==="tv" || media.media_type==="tv")
    
    let watchedListArray = (currentUser && currentUser.watchedList) ? Object.values(currentUser.watchedList) : []
    const watchedMoviesList = watchedListArray.filter(media=>media.mediaType==="movie" || media.media_type==="movie")
    const watchedTvList = watchedListArray.filter(media=>media.mediaType==="tv" || media.media_type==="tv")

    return (
        <div >
            <div className = "account-page" style={{display:'flex', flexDirection: 'column', alignItems: 'center'}}>
                <h1 style={{color: 'white', marginTop: '50px'}}>My Watchlist</h1>
                {watchMoviesList.length 
                    ? <div style={{color: "white", width: '100%'}}>
                        <h2>Movies</h2>
                        <hr />
                        <MediaCardList
                            addToLikedList = {addToLikedList}
                            addToWatchlist = {addToWatchlist} 
                            addToWatchedList = {addToWatchedList} 
                            currentUser={currentUser}
                            removeFromLikedList = {removeFromLikedList}
                            removeFromWatchlist = {removeFromWatchlist} 
                            removeFromWatchedList = {removeFromWatchedList} 
                            mediaList={watchMoviesList}
                            setSelectedEntityId={setSelectedEntityId}
                            setReactGAClickEvent={setReactGAClickEvent}
                        />
                    </div>
                    : null
                }
                {watchTvList.length
                    ? <div style={{color: "white", width: "100%"}}>
                        <h2>TV</h2>
                        <hr />
                        <MediaCardList
                            addToLikedList = {addToLikedList}
                            addToWatchlist = {addToWatchlist} 
                            addToWatchedList = {addToWatchedList} 
                            currentUser={currentUser}
                            removeFromLikedList = {removeFromLikedList}
                            removeFromWatchlist = {removeFromWatchlist} 
                            removeFromWatchedList = {removeFromWatchedList} 
                            mediaList={watchTvList}
                            setSelectedEntityId={setSelectedEntityId}
                            setReactGAClickEvent={setReactGAClickEvent}
                        />
                    </div>
                    : null
                }
                <h1 style={{color: 'white', marginTop: '50px'}}>Liked</h1>
                {likedMoviesList.length 
                    ? <div style={{color: "white", width: '100%'}}>
                        <h2>Movies</h2>
                        <hr />
                        <MediaCardList
                            addToLikedList = {addToLikedList}
                            addToWatchlist = {addToWatchlist} 
                            addToWatchedList = {addToWatchedList} 
                            currentUser={currentUser}
                            removeFromLikedList = {removeFromLikedList}
                            removeFromWatchlist = {removeFromWatchlist} 
                            removeFromWatchedList = {removeFromWatchedList} 
                            mediaList={likedMoviesList}
                            setSelectedEntityId={setSelectedEntityId}
                            setReactGAClickEvent={setReactGAClickEvent}
                        />
                    </div>
                    : null
                }
                {likedTvList.length
                    ? <div style={{color: "white", width: "100%"}}>
                        <h2>TV</h2>
                        <hr />
                        <MediaCardList
                            addToLikedList = {addToLikedList}
                            addToWatchlist = {addToWatchlist} 
                            addToWatchedList = {addToWatchedList} 
                            currentUser={currentUser}
                            removeFromLikedList = {removeFromLikedList}
                            removeFromWatchlist = {removeFromWatchlist} 
                            removeFromWatchedList = {removeFromWatchedList} 
                            mediaList={likedTvList}
                            setSelectedEntityId={setSelectedEntityId}
                            setReactGAClickEvent={setReactGAClickEvent}
                        />
                    </div>
                    : null
                }
                 <h1 style={{color: 'white', marginTop: '50px'}}>Watched</h1>
                {watchedMoviesList.length 
                    ? <div style={{color: "white", width: '100%'}}>
                        <h2>Movies</h2>
                        <hr />
                        <MediaCardList
                            addToLikedList = {addToLikedList}
                            addToWatchlist = {addToWatchlist} 
                            addToWatchedList = {addToWatchedList} 
                            currentUser={currentUser}
                            removeFromLikedList = {removeFromLikedList}
                            removeFromWatchlist = {removeFromWatchlist} 
                            removeFromWatchedList = {removeFromWatchedList} 
                            mediaList={watchedMoviesList}
                            setSelectedEntityId={setSelectedEntityId}
                            setReactGAClickEvent={setReactGAClickEvent}
                        />
                    </div>
                    : null
                }
                {watchedTvList.length
                    ? <div style={{color: "white", width: "100%"}}>
                        <h2>TV</h2>
                        <hr />
                        <MediaCardList
                            addToLikedList = {addToLikedList}
                            addToWatchlist = {addToWatchlist} 
                            addToWatchedList = {addToWatchedList} 
                            currentUser={currentUser}
                            removeFromLikedList = {removeFromLikedList}
                            removeFromWatchlist = {removeFromWatchlist} 
                            removeFromWatchedList = {removeFromWatchedList} 
                            mediaList={watchedTvList}
                            setSelectedEntityId={setSelectedEntityId}
                            setReactGAClickEvent={setReactGAClickEvent}
                        />
                    </div>
                    : null
                }
            </div>
        </div>
    )
}

export default UserAccount