import React from 'react'
import './action-buttons.scss'

import { signInWithGoogle } from '../../utils/firebaseUtils'
import { Tooltip } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

const WatchedButton = ({
    addToWatchedList,
    currentUser,
    mediaProps,
    removeFromWatchedList,
    setReactGAClickEvent
}) => {
    console.log('add to watched list')
    if (currentUser) {
        if (currentUser.watchedList && currentUser.watchedList[mediaProps.id]) {
            return (
                <a>
                    <button
                        className="actions-button"
                        onClick={() => removeFromWatchedList(mediaProps.id)}
                    >
                        <Tooltip title="Remove from Watched List">
                            <VisibilityIcon />
                        </Tooltip>
                    </button>
                </a>
            )
        } else {
            return (
                <a>
                    <button
                        className="actions-button"
                        onClick={() => addToWatchedList(mediaProps)}
                    >
                        <Tooltip title="Add to Watched List">
                            <VisibilityOutlinedIcon />
                        </Tooltip>
                    </button>
                </a>
            )
        }
    } else {
        return (
            <Tooltip title="Sign in with Google to create and share watchlists, likes, and more with friends">
                <a
                    onClick={() => setReactGAClickEvent("add to watched list attempt")}
                >
                    <button
                        className="actions-button"
                        onClick={signInWithGoogle}
                    >
                        <VisibilityOutlinedIcon />
                    </button>
                </a>
            </Tooltip>
        )
    }
}

export default WatchedButton